@if (user.photo_url) {
        <img class="object-cover h-full w-full" [src]="user.photo_url" />
}
@if (!user.photo_url && user.initials) {
        <div class="flex items-center justify-center text-white dark:text-black font-normal h-full leading-none select-none" [ngClass]="backgroundColor">{{user.initials}}</div>
}
@if (!user.photo_url && !user.initials) {
        <div  class="flex items-center justify-center font-normal h-full leading-none select-none bg-qo-light dark:text-black">
                <mat-icon class="material-symbols-outlined text-[1em] h-[1em] w-[1em]">business</mat-icon>
        </div>
}