
@if (canSendPrivateNotes) {
    <ul class="border-b border-black/10 dark:border-white/10 flex">
        <li class="">
            <button type="button" class="relative px-3 py-1"
                (click)="currentView = View.MESSAGE; clear()"
            [ngClass]="currentView === View.MESSAGE ? 'text-qo-primary dark:text-qo-primary300 font-bold after:absolute after:-bottom-px after:h-0.5 after:inset-x-3 after:bg-current' : 'hover:bg-black/5 dark:hover:bg-white/5'" i18n>Message</button>
        </li>
        <li class="">
            <button type="button" class="relative px-3 py-1"
                (click)="currentView = View.PRIVATE_NOTE; clear()"
            [ngClass]="currentView === View.PRIVATE_NOTE ? 'text-qo-primary dark:text-qo-primary300 font-bold after:absolute after:-bottom-px after:h-0.5 after:inset-x-3 after:bg-current' : 'hover:bg-black/5 dark:hover:bg-white/5'" i18n>Private note</button>
        </li>
    </ul>
}

<div class="qo-MessageInput flex flex-col pt-3 md:pt-4 px-3 pb-1 md:pb-2"
[ngClass]="{
        'bg-qo-yellow/30': currentView == View.PRIVATE_NOTE
}" #responsive="responsive" responsive cdkTrapFocus [cdkTrapFocusAutoCapture]="!responsive.XSmall && !responsive.Small">

    @if (currentView == View.MESSAGE) {
        <ng-container *ngTemplateOutlet="messageTemplate"></ng-container>
    }

    @if (currentView == View.PRIVATE_NOTE) {
        <ng-container *ngTemplateOutlet="privateNoteTemplate"></ng-container>
    }

    <ng-template #messageTemplate>
        <textarea class="border-none font-display text-base active:outline-none focus:outline-none bg-transparent" #messageContent name="message" [(ngModel)]="message"  [placeholder]="placeholder"
            [maxlength]="CONSTANTS.MAX_MESSAGE_LENGTH"
            (ngModelChange)="messageChanged(value)" cdkTextareaAutosize cdkAutosizeMaxRows="5" [cdkAutosizeMinRows]="minRows">
        </textarea>

        @if (attachments.length > 0) {
            <div class="flex overflow-auto pt-1.5 pb-0.5">
                @for (attachment of attachments; track attachment) {
                    <div class="relative bg-qo-contrast/10 rounded-xl shrink-0 h-12 overflow-hidden mr-2 cursor-default group" [ngClass]="{
                                'w-20':attachment.isImage
                                }">
                        @if (attachment.isImage) {
                            <img class="object-cover h-full w-full transition-all duration-200 group-hover:opacity-70" [src]="assetFileService.getAssetUrlFromKey(attachment.thumbnail_asset_key) || assetFileService.getAssetUrlFromKey(attachment.asset_key)">
                        }
                        @if (!attachment.isImage) {
                            <div class="flex items-center text-sm w-150px p-1 pr-2 h-full group-hover:opacity-70 transition-all duration-200">
                                <div class="qo-DocIcon mr-2 w-10 h-10 shrink-0 rounded-lg" [attr.data-type]="attachment.extension"></div>
                                <span class="line-clamp-2 text-sm">{{attachment.filename}}</span>
                            </div>
                        }
                        <div class="absolute bg-white dark:bg-qo-dark-800 right-1 top-1 transition-all duration-200 h-8 w-8 rounded-full md:opacity-0 group-hover:md:opacity-100">
                            <button class="h-full w-full p-1" mat-icon-button (click)="removeAttachment(attachment)" matTooltip="Delete attachment" i18n-matTooltip aria-label="Delete" i18n-aria-label>
                                <mat-icon class="material-symbols-outlined">clear</mat-icon>
                            </button>
                        </div>
                    </div>
                }
            </div>
        }

        @if (attached_forms.length > 0) {
            <div class="flex overflow-auto pt-1.5 pb-0.5">
                @for (attached_form of attached_forms; track attached_form; let index = $index) {
                    <div class="relative bg-qo-contrast/10 rounded-xl shrink-0 h-12 overflow-hidden mr-2 cursor-default group">
                        <div class="flex items-center text-sm w-max max-w-280px p-1 pr-2 h-full group-hover:opacity-70 transition-all duration-200">
                            <div class="bg-white dark:bg-qo-dark-800 mr-2 w-10 h-10 shrink-0 rounded-lg flex">
                                <mat-icon class="material-symbols-outlined m-auto">quiz</mat-icon>
                            </div>
                            <span class="line-clamp-2 text-sm">{{attached_form.title|localize:'source'}}</span>
                        </div>
                        <div class="absolute bg-white dark:bg-qo-dark-800 right-1 top-1 transition-all duration-200 h-8 w-8 rounded-full md:opacity-0 group-hover:md:opacity-100">
                            <button class="h-full w-full p-1" mat-icon-button (click)="detachForm(attached_form, index)" matTooltip="Delete form" i18n-matTooltip aria-label="Delete" i18n-aria-label>
                                <mat-icon class="material-symbols-outlined">clear</mat-icon>
                            </button>
                        </div>
                    </div>
                }
            </div>
        }

        <div class="flex items-center justify-between mt-1 -ml-2">
            <div class="flex items-center">
                <div class="relative whitespace-normal" >
                    <button mat-icon-button type="button" (click)="emojiPanelVisible = true" matTooltip="Choose an emoji" i18n-matTooltip aria-label="Choose An Emoji" i18n-aria-label>
                        <mat-icon class="material-symbols-outlined">emoji_emotions</mat-icon>
                    </button>
                    @if (emojiPanelVisible) {
                        <div class="emojiBackdrop fixed inset-0" (click)="emojiPanelVisible = false"></div>
                    }
                    @if (emojiPanelVisible) {
                        <emoji-mart class="emojiPanel mat-elevation-z4 absolute bottom-0 left-0 z-10"
                            [showPreview]="false" [enableSearch]="false" set="google" [exclude]="['flags']"
                        (emojiClick)="addEmoji($event);emojiPanelVisible = false;" color="#00C5E5"></emoji-mart>
                    }
                </div>
                @if (!maxAttachments || attachments.length < maxAttachments) {
                    <button mat-icon-button type="button" (click)="attachmentInput.value = ''; attachmentInput.click();" [disabled]="'uploading'|isLoading|async" matTooltip="Add a picture or file" i18n-matTooltip aria-label="Add a picture or file" i18n-aria-label>
                        @if (!('uploading'|isLoading|async)) {
                            <mat-icon class="material-symbols-outlined">attach_file</mat-icon>
                        }
                        @if ('uploading'|isLoading|async) {
                            <mat-spinner diameter="24"></mat-spinner>
                        }
                    </button>
                }
                @if (canSendForms) {
                    <button mat-icon-button type="button" [matMenuTriggerFor]="menuForms" matTooltip="Add a form" i18n-matTooltip aria-label="Add a form" i18n-aria-label>
                        <mat-icon class="material-symbols-outlined">quiz</mat-icon>
                    </button>
                }
                <mat-menu #menuForms="matMenu">
                    @if (forms && forms.length > 0) {
                        @for (form of forms; track form) {
                            <button (click)="attachForm(form)" [disabled]="formIsAttached(form)" mat-menu-item>{{form.title|localize:'source'}}</button>
                        }
                    }
                    @if (!forms || forms.length === 0) {
                        <p class="text-base italic opacity-60 px-3 py-2 mb-0">You have not created any forms yet.</p>
                    }
                </mat-menu>
            </div>

            <div class="flex items-center">
                @if (enableSms) {
                    <div class="text-qo-text/40 dark:text-white/40 cursor-pointer font-bold pr-2 pl-1" [mdePopoverTriggerFor]="smsPopover" mdePopoverTriggerOn="hover" >
                        @if (!sendSms) {
                            <span [ngClass]="{'text-qo-warn dark:text-qo-dark-warn': !canSendSms }" i18n>SMS</span>
                        }
                        @if (sendSms) {
                            @if (smsCount === -1) {
                                <span class="text-qo-warn dark:text-qo-dark-warn" matBadge="!" matBadgeSize="small" i18n>> 5 SMS</span>
                            }
                            @if (smsCount != -1 && smsCount <= 5 && smsCount > smsSettings?.organization_sms_quota) {
                                <span class="text-qo-warn dark:text-qo-dark-warn" matBadge="!" matBadgeSize="small" i18n>{{smsCount}}/5 SMS</span>
                            }
                            @if (smsCount != -1 && smsCount <= 5 && smsCount <= smsSettings?.organization_sms_quota) {
                                <span class="text-qo-success dark:text-qo-dark-success" i18n>{{smsCount}}/5 SMS</span>
                            }
                        }
                    </div>
                }

                @if (!hideSendButton) {
                    <button class="-mr-2" mat-icon-button type="button" color="primary" (click)="sendMessage(false)" matTooltip="Send message" i18n-matTooltip
                        [disabled]="(message?.length === 0 && attachments.length === 0 && attached_forms.length === 0) || isPendingMessage" aria-label="Send" i18n-aria-label>
                        <mat-icon class="material-symbols-outlined">send</mat-icon>
                    </button>
                }
            </div>
        </div>
    </ng-template>

    <ng-template #privateNoteTemplate>
        <textarea class="border-none font-display text-base active:outline-none focus:outline-none bg-transparent" #messageContent name="message" [(ngModel)]="message"  [placeholder]="placeholder"
            [maxlength]="CONSTANTS.MAX_MESSAGE_LENGTH"
            (ngModelChange)="messageChanged(value)"  cdkTextareaAutosize cdkAutosizeMaxRows="5" [cdkAutosizeMinRows]="minRows">
        </textarea>

        <div class="flex items-center justify-between mt-1 -ml-2">
            <div class="flex items-center">
                <div class="relative whitespace-normal" >
                    <button mat-icon-button type="button" (click)="emojiPanelVisible = true" matTooltip="Choose an emoji" i18n-matTooltip aria-label="Choose An Emoji" i18n-aria-label>
                        <mat-icon class="material-symbols-outlined">emoji_emotions</mat-icon>
                    </button>

                    @if (emojiPanelVisible) {
                        <div class="emojiBackdrop fixed inset-0" (click)="emojiPanelVisible = false"></div>
                    }
                    @if (emojiPanelVisible) {
                        <emoji-mart class="emojiPanel mat-elevation-z4 absolute bottom-0 left-0 z-10"
                            [showPreview]="false" [enableSearch]="false" set="google" [exclude]="['flags']"
                        (emojiClick)="addEmoji($event);emojiPanelVisible = false;" color="#00C5E5"></emoji-mart>
                    }
                </div>
            </div>

            <div class="flex items-center">
                @if (!hideSendButton) {
                    <button class="-mr-2" mat-icon-button type="button" color="primary" (click)="sendMessage(true)" matTooltip="Send message" i18n-matTooltip
                        [disabled]="(message?.length === 0) || isPendingMessage" aria-label="Send" i18n-aria-label>
                        <mat-icon class="material-symbols-outlined">send</mat-icon>
                    </button>
                }
            </div>
        </div>

    </ng-template>

</div>
<input #attachmentInput type="file" (change)="inputFileChange($event.target.files[0])" class="hidden">

<mde-popover #smsPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false" mdePopoverArrowWidth="0">
    <div class="qo-Popover mat-elevation-z2 min-w-300px w-98p sm:w-96 p-2" cdkFocusRegionstart>

        <ng-container >
            @if (enableSms && canSendSms) {
                <div class="flex items-center justify-between">
                    <mat-slide-toggle class="qo-SlideToggleWithBadge" color="primary" name="send_sms" [(ngModel)]="sendSms" (ngModelChange)="onChange(value)">
                        <strong i18n>Send a copy by SMS</strong>
                    </mat-slide-toggle>
                </div>
            }

            @if (sendSms) {
                <div class="mt-2">
                    @if (smsCount !== -1 && canSendSms) {
                        <p class="text-sm">
                            @if (!bulkMessage && nbRecipientsReachableBySms === 1) {
                                <ng-container i18n>This contact will receive a copy in {{smsCount}} SMS sent with the header: <strong>{{smsSettings?.sms_header_override||(smsSettings?.name|localize)}}.</strong></ng-container>
                            }
                            @if (bulkMessage && nbRecipientsReachableBySms === 1) {
                                <ng-container i18n><strong>One</strong> contact will receive a copy in {{smsCount}} SMS sent with the header: <strong>{{smsSettings?.sms_header_override||(smsSettings?.name|localize)}}.</strong></ng-container>
                            }
                            @if (nbRecipientsReachableBySms > 1) {
                                <ng-container i18n><strong>{{nbRecipientsReachableBySms}}</strong> contacts will receive a copy in {{smsCount}} SMS sent with the header: <strong>{{smsSettings?.sms_header_override||(smsSettings?.name|localize)}}.</strong></ng-container>
                            }
                        </p>
                    }
                    @if (smsCount !== -1) {
                        <div class="qo-SMSToSend inline-flex flex-col flex-wrap mr-2 mb-2 pt-2 px-2 pb-1">
                            <span class="text-sm font-medium w-full" i18n>SMS to send</span>
                            <strong class="qo-AccentText text-3xl/tight">{{smsCount * (nbRecipientsReachableBySms >=1 ? nbRecipientsReachableBySms : 1)}}</strong>
                        </div>
                        <div class="qo-SMSAvailables inline-flex flex-col flex-wrap mb-2 pt-2 px-1 pb-1">
                            <span class="text-sm font-medium w-full" i18n>SMS available</span>
                            <div class="flex items-center flex-wrap">
                                <strong class="qo-AccentText text-3xl/tight">{{smsSettings?.organization_sms_quota}}</strong>
                                @if (organization?.user_organization_permissions.includes(OrganizationPermission.ADMIN_ORGANIZATION_MANAGE)) {
                                    <button class="ml-1 uppercase" mat-button color="primary" (click)="openAddSmsDialog()" i18n>Add</button>
                                }
                            </div>
                        </div>
                        @if (attachments.length > 0) {
                            <p class="text-sm last:m-0" i18n><strong class="text-qo-warn dark:text-qo-dark-warn">WARNING:</strong> the attachments will not be sent by SMS.</p>
                        }
                        @if (smsCount > smsSettings?.organization_sms_quota) {
                            <p class="text-sm last:m-0" i18n><strong class="text-qo-warn dark:text-qo-dark-warn">WARNING: you don't have enough SMS available to send a copy of this message.</strong></p>
                        }
                    }
                    @if (smsCount === -1 && canSendSms) {
                        <p class="text-sm last:m-0">
                            <strong class="text-qo-warn dark:text-qo-dark-warn" i18n>WARNING: your message exceeds the limit of 5 SMS. No SMS will be sent.</strong>
                        </p>
                    }
                </div>
            }
        </ng-container>

        @if (nbRecipientsReachableBySms === 0) {
            @if (recipients.length > 1) {
                <p class="text-sm last:m-0">
                    <strong class="text-qo-warn dark:text-qo-dark-warn" i18n>WARNING: none of the selected contacts can be contacted by SMS.</strong>
                </p>
            }
            @if (recipients.length <= 1) {
                <p class="text-sm last:m-0">
                    <strong class="text-qo-warn dark:text-qo-dark-warn" i18n>WARNING: this contact cannot be contacted by SMS.</strong>
                </p>
            }
        }


    </div>
</mde-popover>


