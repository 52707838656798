<div class="@container/cm ">
    <div class="inline-block rounded-xl border border-black/20 dark:border-white/20 p-0.5 max-w-[298px] sm:max-w-[590px] md:max-w-[882px]"
        [ngClass]="{'border-1 border-qo-warn dark:border-qo-dark-warn': invalid }">
        <div class="flex items-center">
            @if (availableDates == undefined) {
                <button (click)="$event.preventDefault(); previousMonth();" class="relative" mat-icon-button matTooltip="Previous month"
                    [ngClass]="{'after:absolute after:top-1 after:right-1 after:content-[\'\'] after:w-2 after:h-2 after:rounded-full after:bg-qo-primary': previousSelectedDatesExist()}" i18n-matTooltip aria-label="Previous Month" i18n-aria-label>
                    <span class="material-symbols-outlined">chevron_left</span>
                </button>
            }

            <div class="ml-auto">
                @if (availableDates == undefined) {
                    <button (click)="$event.preventDefault();" mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Edit the display" i18n-matTooltip aria-label="Edit" i18n-aria-label>
                        <span class="material-symbols-outlined">calendar_view_month</span>
                    </button>
                }
                <mat-menu #menu="matMenu" xPosition="before">
                    <em class="opacity-40 text-sm uppercase" i18n>Display</em>
                    @for (_ of [].constructor(this.maxNumberOfMonth); track _; let i = $index) {
                        <button mat-menu-item (click)="numberOfMonth = i + 1">
                            @if (i == 0) {
                                <span [ngClass]="{'font-bold': numberOfMonth == 1 }" i18n>{{i + 1}} month</span>
                            }
                            @if (i != 0) {
                                <span [ngClass]="{'font-bold': numberOfMonth == i + 1 }" i18n>{{i + 1}} months</span>
                            }
                        </button>
                    }
                </mat-menu>
                @if (availableDates == undefined) {
                    <button (click)="$event.preventDefault(); nextMonth();" class="" mat-icon-button matTooltip="Next month"
                        [ngClass]="{'after:absolute after:top-1 after:left-1 after:content-[\'\'] after:w-2 after:h-2 after:rounded-full after:bg-qo-primary': nextSelectedDatesExist()}" i18n-matTooltip aria-label="Next Month" i18n-aria-label>
                        <span class="material-symbols-outlined">chevron_right</span>
                    </button>
                }
            </div>
        </div>

        <div class="inline-flex flex-wrap gap-4 m-2">
            @for (monthSlot of monthSlots; track monthSlot) {
                <div class="qo-CalendarMonthSlot w-[276px]" [ngClass]="{'qo-read': readOnly }">
                    <div class="flex items-center justify-between overflow-hidden min-h-[37px] mx-1">
                        <span class="text-sm font-medium uppercase">{{monthSlot.monthLabel}}</span>
                        @if (!readOnly) {
                            @if (monthSlot.enableSelectAll) {
                                <button type="button" class="text-sm whitespace-nowrap" mat-stroked-button (click)="selectAll(monthSlot)" i18n>Select all</button>
                            }
                            @if (!monthSlot.enableSelectAll) {
                                <button [ngClass]="{ 'hidden': monthSlot.enableSelectAll == undefined }" type="button" class="text-sm whitespace-nowrap" mat-stroked-button (click)="unselectAll(monthSlot)" i18n>Deselect all</button>
                            }
                        }
                    </div>
                    @if (!readOnly) {
                        <mat-calendar class="mt-4 w-full" #calendar
                            [minDate]="monthSlot.minDate"
                            [maxDate]="monthSlot.maxDate"
                            [dateClass]="isSelected"
                            (selectedChange)="select($event)"
                            [dateFilter]="availableDatesFilter">
                        </mat-calendar>
                    }
                    @if (readOnly) {
                        <mat-calendar class="mt-4 w-full" #calendar
                            [minDate]="monthSlot.minDate"
                            [maxDate]="monthSlot.maxDate"
                            [dateClass]="isSelected"
                            [dateFilter]="availableDatesFilter">
                        </mat-calendar>
                    }
                </div>
            }
        </div>
    </div>
</div>


