import { Directive, ElementRef, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';

@Directive({
    selector: '[domChangesDetector]'
})
export class DomChangesDetectorDirective implements OnInit, OnDestroy {
    @Output() domChanged = new EventEmitter<void>();

    private observer: MutationObserver;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        this.observer = new MutationObserver(() => {
            this.domChanged.emit();
        });

        this.observer.observe(this.el.nativeElement, {
            childList: true,
        });
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}