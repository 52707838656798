
@switch (currentMenuMode) {
        @case (MenuMode.EVENT) {
                <span class="w-7 md:w-10 shrink-0"><img class="rounded" [src]="assetFileService.getAssetUrlFromKey(currentEvent!.poster_asset_key) || 'assets/images/poster-placeholder.svg'" alt="" /></span>
                <span class="text-sm md:shrink-0 line-clamp-1 md:line-clamp-2 md:w-48 cursor-default">{{currentEvent!.name|localize}}</span>
        }
        @case (MenuMode.ORGANIZATION) {
                <span class="w-10 shrink-0"><img class="rounded" [src]="assetFileService.getAssetUrlFromKey(currentOrganization!.logo_asset_key) || 'assets/images/organization-logo-placeholder.svg'" alt="" /></span>
                <span class="text-sm md:shrink-0 line-clamp-1 md:line-clamp-2 md:w-48 cursor-default">{{currentOrganization!.name}}</span>
        }
}





<!--
<div class="flex h-full" [ngSwitch]="currentMenuMode" [mdePopoverTriggerFor]="userPopover" mdePopoverTriggerOn="click" [mdePopoverArrowWidth]="0">
        <button *ngSwitchCase="MenuMode.EVENT"
                matRipple class="block w-full text-base text-qo-inherit p-1">
                <span class="flex items-center justify-between">
                        <img class="block rounded mr-2 w-7 md:w-10" [src]="currentEvent!.poster_url" alt="" *ngIf="currentEvent!.poster_url" />
                        <img class="brightness-90 block rounded mr-2 w-7 md:w-10" src="assets/images/poster-placeholder.svg" alt="" *ngIf="!currentEvent!.poster_url" />
                        <span class="line-clamp-1">{{currentEvent!.name|localize}}</span>
                        <mat-icon class="material-symbols-outlined mx-1 md:ml-auto">expand_more</mat-icon>
                </span>
        </button>
        <button *ngSwitchCase="MenuMode.ORGANIZATION"
                matRipple class="block w-full text-base text-qo-inherit p-1">
                <span class="flex items-center justify-between">
                        <img class="block rounded mr-2 w-7 md:w-10" [src]="currentOrganization!.logo_url" alt="" *ngIf="currentOrganization!.logo_url" />
                        <img class="brightness-90 block rounded mr-2 w-7 md:w-10" src="assets/images/organization-logo-placeholder.svg" alt="" *ngIf="!currentOrganization!.logo_url" />
                        <span class="line-clamp-1">{{currentOrganization!.name}}</span>
                        <mat-icon class="material-symbols-outlined mx-1 md:ml-auto">expand_more</mat-icon>
                </span>
        </button>
</div>


<mde-popover #userPopover="mdePopover" [mdePopoverOverlapTrigger]="false" >
        <div class="mat-elevation-z4 qo-scrollbar flex flex-col md:flex-row max-h-70vh md:max-h-50vh py-4 bg-white dark:bg-qo-dark-800 dark:text-white rounded-md " cdkFocusRegionstart>
                <div class="flex flex-col px-3">
                        <p class="flex items-center text-xl font-medium px-1">
                                <ng-container i18n>Events</ng-container>
                        </p>
                        <ul class="qo-scrollbar flex-1 list-none m-0 p-0" *ngIf="events.length > 0">
                                <li class="mb-1 w-72 sm:w-96 md:pr-1 last:m-0" *ngFor="let event of events">
                                        <a class="flex items-center rounded-lg text-qo-inherit font-medium p-1 transition-all hover:bg-qo-divider dark:hover:bg-white/10" matRipple [routerLink]="NavigationService.EventRoutes.Home(event.id)" [ngClass]="{ 'bg-qo-primary/20 dark:bg-qo-primary/40': event!.id === currentEvent?.id }">
                                                <img class="mr-3 h-20 rounded-md" [src]="event.poster_url" alt="" *ngIf="event.poster_url" />
                                                <img class="mr-3 h-20 rounded-md" src="assets/images/poster-placeholder.svg" alt="" *ngIf="!event.poster_url" />
                                                <span class="line-clamp-3">{{event.name|localize}}</span>
                                        </a>
                                </li>
                        </ul>
                        <p *ngIf="events.length === 0" class="italic opacity-60 px-1 w-72 md:w-96" i18n>You currently have no event.</p>
                </div>
                <div class="flex flex-col px-3 mt-2 md:mt-0 md:border-l md:border-qo-light md:dark:border-gray-700" *ngIf="organizations.length > 0">
                        <p class="flex items-center text-xl font-medium px-1">
                                <ng-container i18n>Organizations</ng-container>
                        </p>
                        <ul class="qo-scrollbar flex-1 list-none m-0 p-0">
                                <li class="mb-1 w-72 sm:w-96 md:pr-1 last:m-0" *ngFor="let organization of organizations">
                                        <a class="flex items-center rounded-lg text-qo-inherit font-medium p-1 transition-all hover:bg-qo-divider dark:hover:bg-white/10" matRipple [routerLink]="NavigationService.OrganizationRoutes.Home(organization!.id)" [ngClass]="{ 'bg-qo-primary/20 dark:bg-qo-primary/40': organization!.id === currentOrganization?.id }">
                                                <img class="mr-3 rounded-md h-20" [src]="organization.logo_url" alt="" *ngIf="organization.logo_url" />
                                                <img class="mr-3 rounded-md h-20" src="assets/images/organization-logo-placeholder.svg" alt="" *ngIf="!organization.logo_url" />
                                                <span class="line-clamp-3">{{organization.name}}</span>
                                        </a>
                                </li>
                        </ul>

                </div>
        </div>
</mde-popover>
-->