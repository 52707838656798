import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { filter, scan } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class RouterHistoryService {
    previousUrl = new BehaviorSubject<string | null>(null);
    currentUrl = new BehaviorSubject<string | null>(null);
    constructor(router: Router) {
        router.events
            .pipe(
                // only include NavigationStart and NavigationEnd events
                filter(
                    event =>
                        event instanceof NavigationStart || event instanceof NavigationEnd
                ),
                scan<NavigationStart | NavigationEnd, RouterHistory>(
                    (accumulator, event) => {
                        if (event instanceof NavigationStart) {
                            // We need to track the trigger, id, and idToRestore from the NavigationStart events
                            return {
                                ...accumulator,
                                event,
                                trigger: event.navigationTrigger,
                                id: event.id,
                                idToRestore:
                                    (event.restoredState && event.restoredState.navigationId) ||
                                    undefined
                            };
                        }
                        // NavigationEnd events
                        const history = [...accumulator.history];
                        let currentIndex = accumulator.currentIndex;
                        // router events are imperative (router.navigate or routerLink)
                        if (accumulator.trigger === 'imperative') {
                            // remove all events in history that come after the current index
                            history.splice(currentIndex + 1);
                            // add the new event to the end of the history and set that as our current index
                            history.push({ id: accumulator.id, url: event.urlAfterRedirects });
                            currentIndex = history.length - 1;
                        }
                        // browser events (back/forward) are popstate events
                        if (accumulator.trigger === 'popstate') {
                            // get the history item that references the idToRestore
                            const idx = history.findIndex(x => x.id === accumulator.idToRestore);
                            // if found, set the current index to that history item and update the id
                            if (idx > -1) {
                                currentIndex = idx;
                                history[idx].id = accumulator.id;
                            } else {
                                currentIndex = 0;
                            }
                        }
                        return {
                            ...accumulator,
                            event,
                            history,
                            currentIndex
                        };
                    },
                    {
                        event: undefined,
                        history: [],
                        trigger: undefined,
                        id: 0,
                        idToRestore: 0,
                        currentIndex: 0
                    }
                ),
                // filter out so we only act when navigation is done
                filter(
                    ({ event, trigger }) => event instanceof NavigationEnd && !!trigger
                )
            )
            .subscribe(({ history, currentIndex }) => {
                const previous = history[currentIndex - 1];
                const current = history[currentIndex];
                // update current and previous urls
                this.previousUrl.next(previous ? previous.url : null);
                this.currentUrl.next(current ? current.url : null);
            });
    }
}

export type NavigationTrigger = 'imperative' | 'popstate' | 'hashchange';

export interface HistoryEntry {
    id: number;
    url: string;
}

export interface RouterHistory {
    history: HistoryEntry[];
    currentIndex: number;

    event?: NavigationStart | NavigationEnd;
    trigger?: NavigationTrigger;
    id: number;
    idToRestore?: number;
}