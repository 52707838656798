<div class="flex flex-col h-full">
        <div class="overflow-auto px-6 py-4">
                @for (group of data.groups; track group) {
                        @if (group.name) {
                                <p class="text-sm font-medium m-0 opacity-60 py-2 px-6">{{group.name}}</p>
                        }
                        <ul class="qo-Menu list-none my-2 mx-0 p-0 rounded-4xl overflow-hidden border border-gray-200 dark:border-gray-700">
                                @for (link of group.links; track link) {
                                        <li class="border-b border-gray-200 dark:border-gray-700 last:border-0">
                                                @if (!link.isExternalUrl) {
                                                        <a class="flex items-center p-4" [routerLink]="link.url" matRipple>
                                                                <mat-icon class="material-symbols-outlined opacity-40">{{link.icon}}</mat-icon>
                                                                <span class="mr-auto ml-3">{{link.title}}</span>
                                                                <mat-icon class="material-symbols-outlined opacity-40">navigate_next</mat-icon>
                                                        </a>
                                                }
                                                @if (link.isExternalUrl) {
                                                        <a class="flex items-center p-4" [href]="link.url" target="_blank" matRipple>
                                                                <mat-icon class="material-symbols-outlined opacity-40">{{link.icon}}</mat-icon>
                                                                <span class="mr-auto ml-3">{{link.title}}</span>
                                                                <mat-icon class="material-symbols-outlined opacity-40">open_in_new</mat-icon>
                                                        </a>
                                                }
                                        </li>
                                }
                        </ul>
                }
        </div>
        <div class="flex justify-center mt-auto py-4">
                @if (data.bottomLink) {
                        <a [routerLink]="data.bottomLink.url" mat-flat-button color="accent">
                                <mat-icon class="material-symbols-outlined">{{data.bottomLink.icon}}</mat-icon>
                                <span>{{data.bottomLink.title}}</span>
                        </a>
                }
        </div>
</div>