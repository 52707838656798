import { Injectable, Injector } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventTeammateCommandDTO } from '../models/event-teammate/commands/EventTeammateCommandDTO';
import { EventTeammateResponseDTO } from '../models/event-teammate/responses/EventTeammateResponseDTO';
import { ExportAttachmentsCommandDTO } from '../models/event-teammate/commands/ExportAttachmentsCommandDTO';
import { AssignableEventTeammateResponseDTO } from '../models/event-teammate/responses/AssignableEventTeammateResponseDTO';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { BaseAPIService } from './base.service';
import { EventTeammateMineResponseDTO } from '../models/event-teammate/responses/EventTeammateMineResponseDTO';
import { ConfirmEventTeammateInvitationCommandDTO } from '../models/event-teammate/commands/ConfirmEventTeammateInvitationCommandDTO';
import { ListAssignableCommandDTO } from '../models/event-teammate/commands/ListAssignableCommandDTO';
import { EventTeammateMaterialSettingsCommandDTO } from '../models/event-teammate/commands/EventTeammateMaterialSettingsCommandDTO';
import { NotifyPlanningCommandDTO } from '../models/event-teammate/commands/NotifyPlanningCommandDTO';
import { BulkUpdateEventTeammateCommandDTO } from '../models/event-teammate/commands/BulkUpdateEventTeammateCommandDTO';
import { AssignmentConstraintViolationErrorResponseDTO } from '../models/event-teammate/responses/errors/AssignmentConstraintViolationErrorResponseDTO';
import { EventTeammateListItemResponseDTO } from '../models/event-teammate-list-item/responses/EventTeammateListItemResponseDTO';
import { StringDTO } from '../models/common/StringDTO';
import { ExportPhotosCommandDTO } from '../models/event-teammate/commands/ExportPhotosCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class EventTeammateAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Creates a new teammate.
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public createTeammate(event_id: string, body: EventTeammateCommandDTO): Observable<EventTeammateResponseDTO> {
        return this.httpPost<EventTeammateResponseDTO>(`/events/${event_id}/teammates`, body);
    }

    /**
     * Get the list of teammates.
     * @param event_id id of the event
     * @param params The httpParams.
     */
    private listTeammatesSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<EventTeammateListItemResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventTeammateListItemResponseDTO>>(`/events/${event_id}/teammates`, { params });
    }

    public listTeammates(event_id: string): Observable<EventTeammateListItemResponseDTO[]> {
        return this.getAllSegments(this.listTeammatesSegment.bind(this), event_id);
    }

    /**
     * Gets the specified teammate.
     *
     * @param id id of the teammate
     * @param event_id id of the event
     */
    public getTeammate(id: string, event_id: string): Observable<EventTeammateResponseDTO> {
        return this.httpGet<EventTeammateResponseDTO>(`/events/${event_id}/teammates/${id}`);
    }

     /**
    * Gets the specified teammate from its user id
    *
    * @param application_user_id id of the teammate associated user
    * @param event_id id of the event
    */
     public getTeammateByUserId(application_user_id: string, event_id: string): Observable<EventTeammateResponseDTO> {
        let params = new HttpParams();
        if (application_user_id !== undefined) params = params.append('application_user_id', application_user_id);
        return this.httpGet<EventTeammateResponseDTO>(`/events/${event_id}/teammates/by-user-id`, { params });
    }

    /**
     * Gets the current teammate's profile.
     *
     * @param event_id id of the event
     */
    public getTeammateMine(event_id: string): Observable<EventTeammateMineResponseDTO> {
        return this.httpGet<EventTeammateMineResponseDTO>(`/events/${event_id}/teammates/mine`);
    }

    /**
     * Updates an existing teammate.
     * @param id id of the teammate
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public updateTeammate(id: string, event_id: string, body: EventTeammateCommandDTO): Observable<EventTeammateResponseDTO> {
        return this.httpPut<EventTeammateResponseDTO>(`/events/${event_id}/teammates/${id}`, body);
    }

    public bulkUpdate(event_id: string, body: BulkUpdateEventTeammateCommandDTO): Observable<AssignmentConstraintViolationErrorResponseDTO> {
        return this.httpPost<AssignmentConstraintViolationErrorResponseDTO>(`/events/${event_id}/teammates/bulk`, body);
    }

    /**
     * Updates an existing teammate material settings
     */
    public updateTeammateMaterialSettings(id: string, event_id: string, body: EventTeammateMaterialSettingsCommandDTO): Observable<void> {
        return this.httpPut<void>(`/events/${event_id}/teammates/${id}/material`, body);
    }

    /**
     * Deletes the specified teammate.
     * @param id id of the teammate
     * @param event_id id of the event
     */
    public deleteTeammate(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/teammates/${id}`);
    }

    /**
     * Withdraws the current user's application from the specified event.
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public withdrawApplication(event_id: string): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/teammates/mine/withdraw-application`, null);
    }

    /**
     * Accepts the current user's teammate planning for the specified event.
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public acceptPlanning(event_id: string): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/teammates/mine/accept-planning`, null);
    }

    private listAssignableSegment(segment_index: number | undefined, query_token: string | undefined, event_id: string, body: ListAssignableCommandDTO): Observable<ResultSegmentDTO<AssignableEventTeammateResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpPost<ResultSegmentDTO<AssignableEventTeammateResponseDTO>>(`/events/${event_id}/teammates/assignable`, body, { params });
    }

    /**
     * Lists all teammates in the specified event that are assignable to all of the specified shifts.
     *
     * @param event_id id of the event
     * @param body Parameters used to list assignable teammates.
     */
    public listAssignable(event_id: string, body: ListAssignableCommandDTO): Observable<AssignableEventTeammateResponseDTO[]> {
        return this.getAllSegments(this.listAssignableSegment.bind(this), event_id, body);
    }

    /**
     * post a user attachments export as a zip request
     * @param event_id id of the event
     * @param body The command used to initialize the list of teammates to process.
     */
    public exportAttachments(event_id: string, body: ExportAttachmentsCommandDTO): Observable<HttpResponse<void>> {
        return this.httpPost<void>(`/events/${event_id}/teammates/attachments/export`, body, undefined, "response");
    }
    
    /**
    * post a user photos export as a zip request
    * @param event_id id of the event
    * @param body The command used to initialize the list of teammates to process.
    */
    public exportPhotos(event_id: string, body: ExportPhotosCommandDTO): Observable<HttpResponse<void>> {
        return this.httpPost<void>(`/events/${event_id}/teammates/photos/export`, body, undefined, "response");
    }

    /**
    * Confirms a teammate invitation by associating the current user to the invited teammate.
    * @param event_id id of the event
    * @param body The invitation confirmation infos.
    */
    public confirmInvitation(event_id: string, body: ConfirmEventTeammateInvitationCommandDTO): Observable<HttpResponse<void>> {
        return this.httpPost<void>(`/events/${event_id}/teammates/invitation/confirmation`, body, undefined, "response");
    }

     /**
    * Sends an email to the specified teammates containing a summary of their planning for this event.
    * @param event_id id of the event
    * @param body Settings to filter teammates to notify.
    */
    public notifyPlanning(event_id: string, body: NotifyPlanningCommandDTO): Observable<HttpResponse<void>> {
        return this.httpPost<void>(`/events/${event_id}/teammates/planning/notify`, body, undefined, "response");
    }
    
    private listTagsSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<StringDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<StringDTO>>(`/events/${event_id}/teammates/tags`, { params });
    }

    /**
     * Lists all event teammates tags.
     * @param event_id id of the event
     */
    public listTags(event_id: string): Observable<string[]> {
       return this.getAllSegments(this.listTagsSegment.bind(this), event_id);
    }
    
    private listAccessGroupsSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<StringDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<StringDTO>>(`/events/${event_id}/teammates/access-groups`, { params });
    }

    /**
     * Lists all teammates access groups.
     * @param event_id id of the event
     */
    public listAccessGroups(event_id: string): Observable<string[]> {
        return this.getAllSegments(this.listAccessGroupsSegment.bind(this), event_id);
    }
}


