enum LocalDateStringBrand { };
export type LocalDateString = string & LocalDateStringBrand;
export class LocalDate {
    private _year: number;
    public get year(): number { return this._year; }
    private _month: number;
    public get month(): number { return this._month; }
    private _day: number;
    public get day(): number { return this._day; }

    static from(value: LocalDateString): LocalDate {
        if (typeof value === 'string') {
            const localDate = new LocalDate();
            localDate._year = Number.parseInt((<string>value).substring(0, 4)); // Extract 4 characters for the year
            localDate._month = Number.parseInt((<string>value).substring(5, 7)); // Extract 2 characters for the month starting after the dash
            localDate._day = Number.parseInt((<string>value).substring(8, 10));
            if (!localDate.isDateValid()) {
                // Check if date is valid, if not throw "INVALID_LOCAL_DATE"
                throw "INVALID_LOCAL_DATE"
            }
            return localDate;
        }
        else {
            throw "STRING_FORMAT_EXPECTED"
        }
    }

    isDateValid() {
        if (
            this._year < 0 ||
            this._month < 1 ||
            this._month > 12 ||
            this._day < 1 ||
            this._day > 31
        ) {
            return false;
        }

        const daysInMonth = [
            31, // January
            this._year % 4 === 0 && (this._year % 100 !== 0 || this._year % 400 === 0) ? 29 : 28,
            31, 30, 31, 30, 31, 31, 30, 31, 30, 31 // Days in other months
        ];

        return this._day <= daysInMonth[this._month - 1];
    }

    toJSON(): string {
        return `${this._year.toString().padStart(4, "0")}-${this._month.toString().padStart(2, "0")}-${this._day.toString().padStart(2, "0")}`;
    }
}